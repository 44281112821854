import logo from './logo.svg';
import './App.css';
import React, { useState } from "react";
// require('dotenv').config()
let apiHost;
if (process.env.NODE_ENV == 'development') {
  apiHost = "http://localhost:5050/fetchTx/";
} else {
  apiHost = "https://api.experimentsfactory.xyz/goerli/fetchTx/";
}
// let apiHost = process.env.API_HOST;
console.log(apiHost);
var allowSubmit = true;
  console.log(allowSubmit)

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function App() {
  const [walletAddr, setAddr] = useState("");
  // const [email, setEmail] = useState("");
  // const [mobileNumber, setMobileNumber] = useState("");
  const [message, setMessage] = useState("");

  let handleSubmit = async (e) => {
    e.preventDefault();
    let timeOut = 300;
    if (allowSubmit === true){
      try {
        for (let j = 0; j <= timeOut; j++) {
          if (j === timeOut) {
            document.getElementsByClassName("timer")[0].innerHTML = "Timed out. Click listen to try again.";
            allowSubmit = true;
            break;
          } else {
            document.getElementsByClassName("timer")[0].innerHTML = "Listening for " + j + " second(s)...";
          }
          console.log("Listening for " + j + " second(s)...")

          for (let t = 0; t < 2; t++) {
            // console.log(`Waiting ${t} seconds...`);
            await sleep(t * 1000);
          }

          let query = apiHost + walletAddr;
          console.log(query)
          let res = await fetch(query, {
            method: "GET",
            // body: JSON.stringify({
            //   name: name,
            //   email: email,
            //   mobileNumber: mobileNumber,
            // }),
          });
          // setMessage("Listening for transactions...");
          let resJson = await res.json();
          console.log(resJson);
          let stringRes = JSON.stringify(resJson);
          if (res.status === 200) {
            // setName("");
            // setEmail("");
            // setMobileNumber("");
            setMessage(stringRes);
            allowSubmit = false;
            console.log(allowSubmit)
            document.getElementsByClassName("stream")[0].innerHTML += "<div class='streamItem'>" + stringRes + "</div>";
            window.scrollTo(0, document.body.scrollHeight);
          } else if (res.status === 404) {
            setMessage("searching...");
            document.getElementsByClassName("stream")[0].innerHTML += "<div class='streamItem'>" + "searching..." + "</div>";
            // window.scrollTo(0, document.streamDiv.scrollHeight);
            window.scrollBy(0,10);
            allowSubmit = false;
            console.log(allowSubmit)
          } else if (res.status === 429) {
            alert("Too many requests. Please wait a few minutes and try again.")
            setMessage("too many requests! you sneaky dog");
            document.getElementsByClassName("stream")[0].innerHTML += "<div class='streamItem'>" + "too many requests! you sneaky dog" + "</div>";
            allowSubmit = false;
            break;
          } else if (res.status === 400) {
            alert("Please enter a valid ethereum address.")
            allowSubmit = true;
            break;
          } else {
            setMessage("Something went wrong. Please try again.");
            allowSubmit = true;
            console.log(allowSubmit)
          }
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("Already listening. Refresh the page to change the address.")
    }
  };

  return (
    <div className="App">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={walletAddr}
          placeholder="Wallet Address"
          onChange={(e) => setAddr(e.target.value)}
          required
        />
        {/* <input
          type="text"
          value={email}
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="text"
          value={mobileNumber}
          placeholder="Mobile Number"
          onChange={(e) => setMobileNumber(e.target.value)}
        /> */}

        <button type="monitor">Listen</button>
        </form>

        <div className="terminal space shadow">
          <div className="terminal-body">
            <div className="top">
              <div className="btns">
                <span className="circle red"></span>
                <span className="circle yellow"></span>
                <span className="circle green"></span>
              </div>
              <div className="title">bash -- 70x32</div>
            </div>
            <pre className="body">
              <div className="timer">NOT LISTENING...</div>
              <div className="message">{message ? <p>current status: {message}</p> : null}</div>
              <div className="divider"></div>
              <div className="stream" id="streamDiv"></div>
            </pre>
          </div>
        </div>
      
    </div>
  );
}

export default App;
